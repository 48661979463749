import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
};

/** Describes access permissions to an application. */
export type AppAccess = {
  __typename?: 'AppAccess';
  /** Textual ID for identifying the app. */
  id: Scalars['String'];
};



export type Debtor = {
  __typename?: 'Debtor';
  id: Scalars['ID'];
};

export type DebtorInvoice = {
  __typename?: 'DebtorInvoice';
  id: Scalars['ID'];
};

export type DebtorInvoiceLine = {
  __typename?: 'DebtorInvoiceLine';
  id: Scalars['ID'];
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
};

export type Fulfilment = {
  __typename?: 'Fulfilment';
  id: Scalars['ID'];
};

export type FulfilmentLine = {
  __typename?: 'FulfilmentLine';
  id: Scalars['ID'];
};

export type FulfilmentLinePack = {
  __typename?: 'FulfilmentLinePack';
  id: Scalars['ID'];
};

export type Kiln = {
  __typename?: 'Kiln';
  id: Scalars['ID'];
};

export type KilnCharge = {
  __typename?: 'KilnCharge';
  chargeNumber: Scalars['String'];
  id: Scalars['ID'];
};

export type KilnChargeItem = {
  __typename?: 'KilnChargeItem';
  id: Scalars['ID'];
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Create a manual upload event so that details can be given for the specified
   * packs.
   */
  receiptCreateManual: ReceiptCreateManualPayload;
  /** Delete packs. The packs must all be `NEW`. */
  receiptDeletePacks: ReceiptDeletePacksPayload;
  /** Modify the tally on a pack. The pack must be `NEW`. */
  receiptPackReplaceTally: ReceiptPackReplaceTallyPayload;
  /**
   * Modify the piece count on a pack. The pack must be `NEW`, and must be of a
   * set-length product.
   */
  receiptPackSetPieceCount: ReceiptPackSetPieceCountPayload;
  /** Replace the kiln dry date on a pack. */
  receiptPackUpdateKilnDryDate: ReceiptPackUpdateKilnDryDatePayload;
  /** Replace the product code on a pack. */
  receiptPackUpdateProductCode: ReceiptPackUpdateProductCodePayload;
  /** Update the purchase order on packs. The packs must all be `NEW`. */
  receiptPacksUpdatePurchaseOrderNumber: ReceiptPacksUpdatePurchaseOrderNumberPayload;
  /** Indicate that the specified packs have arrived on-site. */
  receiptScanPacks: ReceiptScanPacksPayload;
  /**
   * Sends packs to the inventory system. All packs given must be `NEW` and
   * sendable.
   */
  receiptSendPacks: ReceiptSendPacksPayload;
  /** Upload data obtained via supplier delivery dockets. */
  receiptSubmitUpload: ReceiptSubmitUploadPayload;
  /** Replace the date dispatched on an upload event. */
  receiptUploadUpdateDateDispatched: ReceiptUploadUpdateDateDispatchedPayload;
  /** Replace the reference on an upload event. */
  receiptUploadUpdateReference: ReceiptUploadUpdateReferencePayload;
};


export type MutationReceiptCreateManualArgs = {
  input: ReceiptCreateManualInput;
};


export type MutationReceiptDeletePacksArgs = {
  input: ReceiptDeletePacksInput;
};


export type MutationReceiptPackReplaceTallyArgs = {
  input: ReceiptPackReplaceTallyInput;
};


export type MutationReceiptPackSetPieceCountArgs = {
  input: ReceiptPackSetPieceCountInput;
};


export type MutationReceiptPackUpdateKilnDryDateArgs = {
  input: ReceiptPackUpdateKilnDryDateInput;
};


export type MutationReceiptPackUpdateProductCodeArgs = {
  input: ReceiptPackUpdateProductCodeInput;
};


export type MutationReceiptPacksUpdatePurchaseOrderNumberArgs = {
  input: ReceiptPacksUpdatePurchaseOrderNumberInput;
};


export type MutationReceiptScanPacksArgs = {
  input: ReceiptScanPacksInput;
};


export type MutationReceiptSendPacksArgs = {
  input: ReceiptSendPacksInput;
};


export type MutationReceiptSubmitUploadArgs = {
  input: ReceiptSubmitUploadInput;
};


export type MutationReceiptUploadUpdateDateDispatchedArgs = {
  input: ReceiptUploadUpdateDateDispatchedInput;
};


export type MutationReceiptUploadUpdateReferenceArgs = {
  input: ReceiptUploadUpdateReferenceInput;
};

export type PackLog = {
  __typename?: 'PackLog';
  id: Scalars['ID'];
  packNumber: Scalars['String'];
};

/** Describes a list of packs purchased/to purchase. */
export type PackPurchaseOrder = {
  __typename?: 'PackPurchaseOrder';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** List of items on the purchase order. */
  items: Array<PackPurchaseOrderItem>;
};

/** A pack that is/is to be purchased. */
export type PackPurchaseOrderItem = {
  __typename?: 'PackPurchaseOrderItem';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Code for the product required. */
  productCode: Scalars['String'];
};

export type PackWorkstation = {
  __typename?: 'PackWorkstation';
  id: Scalars['ID'];
};

export type PickGroup = {
  __typename?: 'PickGroup';
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _null?: Maybe<Scalars['String']>;
  /** Gets the current user as recognized by the authentication system. */
  me?: Maybe<User>;
  /** Fetch packs, in the order of most to least recently created. */
  packPurchaseOrders: Array<PackPurchaseOrder>;
  /** Fetch packs, in the order of most to least recently created. */
  receiptPacks: Array<ReceiptPack>;
  /** Fetch list of suppliers who send us timber to receipt. */
  receiptSuppliers: Array<ReceiptSupplier>;
  /** Fetch upload events, in the order of most recent to least recent. */
  receiptUploadEvents: Array<ReceiptUploadEvent>;
  /** Get's the `User` with the given ID, or null if not found. */
  user?: Maybe<User>;
  /**
   * Fetches a `User` by their email.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByEmail?: Maybe<User>;
  /**
   * Attempts to log in using the given PIN.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByPin?: Maybe<User>;
  /** Get's the `User`s with the given IDs or all `User`s. */
  users: Array<User>;
};


export type QueryPackPurchaseOrdersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryReceiptPacksArgs = {
  take?: Maybe<Scalars['Int']>;
  type?: Maybe<ReceiptPackType>;
};


export type QueryReceiptUploadEventsArgs = {
  status?: Maybe<ReceiptUploadEventStatus>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByPinArgs = {
  pin: Scalars['String'];
};


export type QueryUsersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type ReceiptCreateManualConflictingPacksError = UserError & {
  __typename?: 'ReceiptCreateManualConflictingPacksError';
  message: Scalars['String'];
};

export type ReceiptCreateManualError = ReceiptCreateManualConflictingPacksError | ReceiptCreateManualInvalidInputError | ReceiptCreateManualNotFoundError;

export type ReceiptCreateManualInput = {
  /** List of packs details will be provided for. */
  packIds: Array<Scalars['ID']>;
  /** Supplier who sent the packs. */
  supplierId: Scalars['ID'];
};

export type ReceiptCreateManualInvalidInputError = UserError & {
  __typename?: 'ReceiptCreateManualInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptCreateManualNotFoundError = UserError & {
  __typename?: 'ReceiptCreateManualNotFoundError';
  message: Scalars['String'];
};

export type ReceiptCreateManualPayload = {
  __typename?: 'ReceiptCreateManualPayload';
  /** The created `ReceiptUploadEvent`, null if no packs given or on failure. */
  uploadEvent?: Maybe<ReceiptUploadEvent>;
  userErrors: Array<ReceiptCreateManualError>;
};

export type ReceiptDeletePacksError = ReceiptDeletePacksNotFoundError;

export type ReceiptDeletePacksInput = {
  /** The packs to delete. */
  packIds: Array<Scalars['ID']>;
};

export type ReceiptDeletePacksNotFoundError = UserError & {
  __typename?: 'ReceiptDeletePacksNotFoundError';
  message: Scalars['String'];
};

export type ReceiptDeletePacksPayload = {
  __typename?: 'ReceiptDeletePacksPayload';
  userErrors: Array<ReceiptDeletePacksError>;
};

export type ReceiptPack = {
  __typename?: 'ReceiptPack';
  /**
   * Date the pack was registered. This will be the `dateSubmitted` of the
   * earliest `ReceiptUploadEvent` or `ReceiptScanEvent` mentioning the pack.
   */
  dateCreated: Scalars['DateTime'];
  /** Date the pack most recently had it's `status` field changed */
  dateTransitioned: Scalars['DateTime'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Date the wood was kiln-dried. */
  kilnDryDate?: Maybe<Scalars['Date']>;
  /** Unique ID for the pack, typically generated by the supplier */
  packNumber: Scalars['String'];
  /** Number of pieces, non-null only if the product is of a set length */
  pieceCount?: Maybe<Scalars['Float']>;
  /** Our product code for the product in the pack, if it could be determined. */
  productCode?: Maybe<Scalars['String']>;
  /** The purchase order that triggered the purchase of the goods in the pack. */
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  scanEvent?: Maybe<ReceiptScanEvent>;
  /**
   * Whether or not this pack could be sent in its current state into the inventory
   * system.
   */
  sendable: Scalars['Boolean'];
  /** The status of the pack. */
  status: ReceiptPackStatus;
  /**
   * Supplier's product code (the code typically present in the delivery
   * docket) for the product in the pack.
   */
  supplierProductCode?: Maybe<Scalars['String']>;
  tally: Array<ReceiptPackTally>;
  uploadEvent?: Maybe<ReceiptUploadEvent>;
};

export type ReceiptPackReplaceTallyError = ReceiptPackReplaceTallyInvalidInputError | ReceiptPackReplaceTallyNotFoundError | ReceiptPackReplaceTallyProductKindError;

export type ReceiptPackReplaceTallyInput = {
  /** The pack to modify. */
  packId: Scalars['ID'];
  /** The tally to associate with the pack. */
  tally: Array<TallyInput>;
};

export type ReceiptPackReplaceTallyInvalidInputError = UserError & {
  __typename?: 'ReceiptPackReplaceTallyInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPackReplaceTallyNotFoundError = UserError & {
  __typename?: 'ReceiptPackReplaceTallyNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackReplaceTallyPayload = {
  __typename?: 'ReceiptPackReplaceTallyPayload';
  /** The modified pack. */
  pack?: Maybe<ReceiptPack>;
  userErrors: Array<ReceiptPackReplaceTallyError>;
};

export type ReceiptPackReplaceTallyProductKindError = UserError & {
  __typename?: 'ReceiptPackReplaceTallyProductKindError';
  message: Scalars['String'];
};

export type ReceiptPackSetPieceCountError = ReceiptPackSetPieceCountInvalidInputError | ReceiptPackSetPieceCountNotFoundError | ReceiptPackSetPieceCountProductKindError;

export type ReceiptPackSetPieceCountInput = {
  /** The pack to modify. */
  packId: Scalars['ID'];
  /** The number of pieces to set. */
  pieceCount: Scalars['Float'];
};

export type ReceiptPackSetPieceCountInvalidInputError = UserError & {
  __typename?: 'ReceiptPackSetPieceCountInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPackSetPieceCountNotFoundError = UserError & {
  __typename?: 'ReceiptPackSetPieceCountNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackSetPieceCountPayload = {
  __typename?: 'ReceiptPackSetPieceCountPayload';
  /** The modified pack. */
  pack?: Maybe<ReceiptPack>;
  userErrors: Array<ReceiptPackSetPieceCountError>;
};

export type ReceiptPackSetPieceCountProductKindError = UserError & {
  __typename?: 'ReceiptPackSetPieceCountProductKindError';
  message: Scalars['String'];
};

export enum ReceiptPackStatus {
  /**
   * The pack is not in the inventory system, and never will be, for whatever
   * reason.
   */
  Deleted = 'DELETED',
  /**
   * The pack is not yet in the inventory system - it is either waiting for manual
   * input, or additional verification (scan event or upload event).
   */
  New = 'NEW',
  /**
   * The pack is currently being registered in the inventory system. If a pack
   * remains stuck in this state, that may indicate that the pack could not
   * successfully be created in the inventory system.
   */
  Sending = 'SENDING',
  /** The pack is in the inventory system, and so can no longer be modified. */
  Sent = 'SENT'
}

export type ReceiptPackTally = {
  __typename?: 'ReceiptPackTally';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** A length of a number of goods in a pack. */
  length: Scalars['Float'];
  /** Number of pieces of the given length. */
  pieces: Scalars['Int'];
};

export enum ReceiptPackType {
  /** The pack is scanned but not yet uploaded. */
  Scanned = 'SCANNED',
  /** The pack is uploaded but not yet scanned. */
  Uploaded = 'UPLOADED'
}

export type ReceiptPackUpdateKilnDryDateError = ReceiptPackUpdateKilnDryDateNotFoundError;

export type ReceiptPackUpdateKilnDryDateInput = {
  /** The kiln dry date to set on the pack. */
  kilnDryDate?: Maybe<Scalars['Date']>;
  /** The packs to modify. */
  packIds: Array<Scalars['ID']>;
};

export type ReceiptPackUpdateKilnDryDateNotFoundError = UserError & {
  __typename?: 'ReceiptPackUpdateKilnDryDateNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackUpdateKilnDryDatePayload = {
  __typename?: 'ReceiptPackUpdateKilnDryDatePayload';
  /** The modified packs. */
  packs?: Maybe<Array<ReceiptPack>>;
  userErrors: Array<ReceiptPackUpdateKilnDryDateError>;
};

export type ReceiptPackUpdateProductCodeError = ReceiptPackUpdateProductCodeInvalidInputError | ReceiptPackUpdateProductCodeNotFoundError;

export type ReceiptPackUpdateProductCodeInput = {
  /** The pack to modify. */
  packId: Scalars['ID'];
  /** The product code to set on the pack. May be `null`, but not an empty string. */
  productCode?: Maybe<Scalars['String']>;
};

export type ReceiptPackUpdateProductCodeInvalidInputError = UserError & {
  __typename?: 'ReceiptPackUpdateProductCodeInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPackUpdateProductCodeNotFoundError = UserError & {
  __typename?: 'ReceiptPackUpdateProductCodeNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPackUpdateProductCodePayload = {
  __typename?: 'ReceiptPackUpdateProductCodePayload';
  /** The modified pack. */
  pack?: Maybe<ReceiptPack>;
  userErrors: Array<ReceiptPackUpdateProductCodeError>;
};

export type ReceiptPacksUpdatePurchaseOrderNumberError = ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError | ReceiptPacksUpdatePurchaseOrderNumberNotFoundError;

export type ReceiptPacksUpdatePurchaseOrderNumberInput = {
  /** The packs to delete. */
  packIds: Array<Scalars['ID']>;
  /** The purchase order number to set on the packs. */
  purchaseOrderNumber: Scalars['String'];
};

export type ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError = UserError & {
  __typename?: 'ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptPacksUpdatePurchaseOrderNumberNotFoundError = UserError & {
  __typename?: 'ReceiptPacksUpdatePurchaseOrderNumberNotFoundError';
  message: Scalars['String'];
};

export type ReceiptPacksUpdatePurchaseOrderNumberPayload = {
  __typename?: 'ReceiptPacksUpdatePurchaseOrderNumberPayload';
  /** The modified packs */
  packs?: Maybe<Array<ReceiptPack>>;
  userErrors: Array<ReceiptPacksUpdatePurchaseOrderNumberError>;
};

export type ReceiptScanEvent = {
  __typename?: 'ReceiptScanEvent';
  /** Date of the upload. */
  dateSubmitted: Scalars['DateTime'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The list of packs associated with the `ReceiptScanEvent`. */
  packs: Array<ReceiptPack>;
  /** Free text reference. */
  reference: Scalars['String'];
  /** The `User` that scanned the physical goods. */
  scannedBy: User;
  /** Supplier who sent the packs. */
  supplier: ReceiptSupplier;
};

export type ReceiptScanPacksConflictingPacksError = UserError & {
  __typename?: 'ReceiptScanPacksConflictingPacksError';
  message: Scalars['String'];
};

export type ReceiptScanPacksError = ReceiptScanPacksConflictingPacksError | ReceiptScanPacksInvalidInputError;

export type ReceiptScanPacksInput = {
  /** List of packs that were scanned. */
  packNumbers: Array<Scalars['String']>;
  /** Free text reference. */
  reference: Scalars['String'];
  /** The supplier who sent the packs. */
  supplierId: Scalars['ID'];
};

export type ReceiptScanPacksInvalidInputError = UserError & {
  __typename?: 'ReceiptScanPacksInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptScanPacksPayload = {
  __typename?: 'ReceiptScanPacksPayload';
  /** The created `ReceiptScanEvent`, null on failure. */
  scanEvent?: Maybe<ReceiptScanEvent>;
  userErrors: Array<ReceiptScanPacksError>;
};

export type ReceiptSendPacksError = ReceiptSendPacksNotFoundError | ReceiptSendPacksNotSendableError | ReceiptSendPacksSendingFailedError;

export type ReceiptSendPacksInput = {
  /** If set, will not allow sending packs that are in `SENDING` status. */
  allowRetry?: Maybe<Scalars['Boolean']>;
  /** The packs to send. */
  packIds: Array<Scalars['ID']>;
};

export type ReceiptSendPacksNotFoundError = UserError & {
  __typename?: 'ReceiptSendPacksNotFoundError';
  message: Scalars['String'];
};

export type ReceiptSendPacksNotSendableError = UserError & {
  __typename?: 'ReceiptSendPacksNotSendableError';
  message: Scalars['String'];
};

export type ReceiptSendPacksPayload = {
  __typename?: 'ReceiptSendPacksPayload';
  /** The packs sent, null on `NotFound` and `NotSendable` errors. */
  packs?: Maybe<Array<ReceiptPack>>;
  userErrors: Array<ReceiptSendPacksError>;
};

export type ReceiptSendPacksSendingFailedError = UserError & {
  __typename?: 'ReceiptSendPacksSendingFailedError';
  message: Scalars['String'];
};

export type ReceiptSubmitUploadConflictingPacksError = UserError & {
  __typename?: 'ReceiptSubmitUploadConflictingPacksError';
  message: Scalars['String'];
};

export type ReceiptSubmitUploadDocketInput = {
  /**
   * Date upon which the contents originally specified in the delivery docket were
   * dispatched from the supplier.
   */
  dateDispatched?: Maybe<Scalars['Date']>;
  /** List of packs that were delivered. */
  packs: Array<ReceiptSubmitUploadPackInput>;
  /** Reference number for the `PurchaseOrder`. */
  reference?: Maybe<Scalars['String']>;
  /**
   * The supplier that sent the delivery docket. The available suppliers can be
   * queried with `receiptSuppliers`
   */
  supplierId: Scalars['ID'];
};

export type ReceiptSubmitUploadError = ReceiptSubmitUploadConflictingPacksError | ReceiptSubmitUploadInvalidInputError;

export type ReceiptSubmitUploadInput = {
  /** The data from the dockets to upload. */
  dockets: Array<ReceiptSubmitUploadDocketInput>;
};

export type ReceiptSubmitUploadInvalidInputError = UserError & {
  __typename?: 'ReceiptSubmitUploadInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptSubmitUploadPackInput = {
  /** Date that the wood inside the pack was kiln dried. */
  kilnDryDate?: Maybe<Scalars['Date']>;
  /** Identifier for the pack that was/is being delivered. */
  packNumber: Scalars['String'];
  /**
   * If provided, and if the product is of a set-length in the inventory system,
   * will use this as the piece count, rather than calculating using the provided
   * tally. A tally must still be provided, to ensure that if for some reason the
   * product is not a set-length product, the application can still proceed.
   */
  pieceCountOverride?: Maybe<Scalars['Int']>;
  /** Our product code for the product in the pack, if it could be determined. */
  productCode?: Maybe<Scalars['String']>;
  /** The purchase order number the pack corresponds to. */
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  /**
   * Supplier's product code (the code typically present in the delivery
   * docket) for the product in the pack.
   */
  supplierProductCode: Scalars['String'];
  /** Tally specification for the pack. */
  tally: Array<TallyInput>;
};

export type ReceiptSubmitUploadPayload = {
  __typename?: 'ReceiptSubmitUploadPayload';
  /** The created `ReceiptUploadEvent`s, null on failure. */
  uploadEvents?: Maybe<Array<ReceiptUploadEvent>>;
  userErrors: Array<ReceiptSubmitUploadError>;
};

/** A supplier from which we may choose to purchase packs. */
export type ReceiptSupplier = {
  __typename?: 'ReceiptSupplier';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Supplier name. */
  name: Scalars['String'];
};

export type ReceiptUploadEvent = {
  __typename?: 'ReceiptUploadEvent';
  /** Date the corresponding packs were dispatched from the supplier. */
  dateDispatched?: Maybe<Scalars['Date']>;
  /** Date of the upload. */
  dateSubmitted: Scalars['DateTime'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The list of packs associated with the `ReceiptUploadEvent`. */
  packs: Array<ReceiptPack>;
  /** Free-form reference to aid in searchability. */
  reference: Scalars['String'];
  /** Supplier the upload (delivery docket) came from. */
  supplier: ReceiptSupplier;
  /** Type of the upload event. */
  type: ReceiptUploadEventType;
};

export enum ReceiptUploadEventStatus {
  /** Only fetch upload events that don't have NEW/SENDING packs. */
  Complete = 'COMPLETE',
  /** Only fetch upload events that have NEW/SENDING packs. */
  Incomplete = 'INCOMPLETE'
}

export enum ReceiptUploadEventType {
  /** The `UploadEvent` was created manually to provide details of scanned packs. */
  Manual = 'MANUAL',
  /**
   * The `UploadEvent` was generated automatically from delivery dockets or other
   * data sent by the supplier.
   */
  Upload = 'UPLOAD'
}

export type ReceiptUploadUpdateDateDispatchedError = ReceiptUploadUpdateDateDispatchedNotFoundError;

export type ReceiptUploadUpdateDateDispatchedInput = {
  /** The date dispatched to set on the upload event. */
  dateDispatched?: Maybe<Scalars['String']>;
  /** The event to modify. */
  uploadEventId: Scalars['ID'];
};

export type ReceiptUploadUpdateDateDispatchedNotFoundError = UserError & {
  __typename?: 'ReceiptUploadUpdateDateDispatchedNotFoundError';
  message: Scalars['String'];
};

export type ReceiptUploadUpdateDateDispatchedPayload = {
  __typename?: 'ReceiptUploadUpdateDateDispatchedPayload';
  /** The modified upload event. */
  uploadEvent?: Maybe<ReceiptUploadEvent>;
  userErrors: Array<ReceiptUploadUpdateDateDispatchedError>;
};

export type ReceiptUploadUpdateReferenceError = ReceiptUploadUpdateReferenceInvalidInputError | ReceiptUploadUpdateReferenceNotFoundError;

export type ReceiptUploadUpdateReferenceInput = {
  /** The reference to set on the upload event. */
  reference: Scalars['String'];
  /** The event to modify. */
  uploadEventId: Scalars['ID'];
};

export type ReceiptUploadUpdateReferenceInvalidInputError = UserError & {
  __typename?: 'ReceiptUploadUpdateReferenceInvalidInputError';
  message: Scalars['String'];
};

export type ReceiptUploadUpdateReferenceNotFoundError = UserError & {
  __typename?: 'ReceiptUploadUpdateReferenceNotFoundError';
  message: Scalars['String'];
};

export type ReceiptUploadUpdateReferencePayload = {
  __typename?: 'ReceiptUploadUpdateReferencePayload';
  /** The modified upload event. */
  uploadEvent?: Maybe<ReceiptUploadEvent>;
  userErrors: Array<ReceiptUploadUpdateReferenceError>;
};

export type Run = {
  __typename?: 'Run';
  id: Scalars['ID'];
};

export type RunInfeedPack = {
  __typename?: 'RunInfeedPack';
  packId: Scalars['ID'];
};

export type RunOutfeedPack = {
  __typename?: 'RunOutfeedPack';
  packId: Scalars['ID'];
};

export type SalesBranch = {
  __typename?: 'SalesBranch';
  id: Scalars['ID'];
};

export type SalesOrder = {
  __typename?: 'SalesOrder';
  id: Scalars['ID'];
};

export type SalesOrderLine = {
  __typename?: 'SalesOrderLine';
  id: Scalars['ID'];
};

export type ShippingProvider = {
  __typename?: 'ShippingProvider';
  id: Scalars['ID'];
};

export type StockGroup = {
  __typename?: 'StockGroup';
  id: Scalars['ID'];
};

export type StockItem = {
  __typename?: 'StockItem';
  stockCode: Scalars['ID'];
};

export type StockLocation = {
  __typename?: 'StockLocation';
  id: Scalars['ID'];
};

export type TallyInput = {
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

/** A user of MLC applications. */
export type User = {
  __typename?: 'User';
  /** The list of apps the user has access to. */
  apps: Array<AppAccess>;
  /**
   * Default `StockLocation` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultFulfillingStockLocation?: Maybe<StockLocation>;
  /**
   * Default `PickGroup` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultPickGroup?: Maybe<PickGroup>;
  /**
   * The user's email address, if any are on record. This allows the gateway and
   * services under it to identify a user logged in directly via the gateway, in a
   * way that from a service's perspective behaves identically to being logged in
   * via middleware-clients.
   */
  email?: Maybe<Scalars['String']>;
  /** The user's family (last) name. */
  familyName: Scalars['String'];
  /** The ID of the warehouse staff member in the the financial system. */
  financialWarehouseStaffMemberId?: Maybe<Scalars['ID']>;
  /** The set of branches the user may fulfil from. */
  fulfillingStockLocations: Array<StockLocation>;
  /** The user's given (first) name. */
  givenName: Scalars['String'];
  id: Scalars['ID'];
  /** The set of picking groups the user may fulfil from. */
  pickGroups: Array<PickGroup>;
};

export type UserError = {
  message: Scalars['String'];
};

export type WipSalesOrder = {
  __typename?: 'WipSalesOrder';
  id: Scalars['ID'];
};

export type Workstation = {
  __typename?: 'Workstation';
  id: Scalars['ID'];
};

export type PendingPacksQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingPacksQuery = (
  { __typename?: 'Query' }
  & { uploaded: Array<(
    { __typename?: 'ReceiptUploadEvent' }
    & Pick<ReceiptUploadEvent, 'id' | 'type' | 'dateSubmitted' | 'dateDispatched' | 'reference'>
    & { supplier: (
      { __typename?: 'ReceiptSupplier' }
      & Pick<ReceiptSupplier, 'id' | 'name'>
    ), packs: Array<(
      { __typename?: 'ReceiptPack' }
      & { scanEvent?: Maybe<(
        { __typename?: 'ReceiptScanEvent' }
        & Pick<ReceiptScanEvent, 'id'>
        & { scannedBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'givenName'>
        ) }
      )> }
      & PackInfoFragment
    )> }
  )>, scanned: Array<(
    { __typename?: 'ReceiptPack' }
    & { scanEvent?: Maybe<(
      { __typename?: 'ReceiptScanEvent' }
      & Pick<ReceiptScanEvent, 'id' | 'dateSubmitted' | 'reference'>
      & { supplier: (
        { __typename?: 'ReceiptSupplier' }
        & Pick<ReceiptSupplier, 'id' | 'name'>
      ), scannedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'givenName'>
      ) }
    )> }
    & PackInfoFragment
  )> }
);

export type ProductCodesQueryVariables = Exact<{
  purchaseOrderIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ProductCodesQuery = (
  { __typename?: 'Query' }
  & { packPurchaseOrders: Array<(
    { __typename?: 'PackPurchaseOrder' }
    & Pick<PackPurchaseOrder, 'id'>
    & { items: Array<(
      { __typename?: 'PackPurchaseOrderItem' }
      & Pick<PackPurchaseOrderItem, 'id' | 'productCode'>
    )> }
  )> }
);

export type MatchedPacksQueryVariables = Exact<{
  take: Scalars['Int'];
}>;


export type MatchedPacksQuery = (
  { __typename?: 'Query' }
  & { matched: Array<(
    { __typename?: 'ReceiptUploadEvent' }
    & Pick<ReceiptUploadEvent, 'id' | 'type' | 'dateSubmitted' | 'dateDispatched' | 'reference'>
    & { supplier: (
      { __typename?: 'ReceiptSupplier' }
      & Pick<ReceiptSupplier, 'id' | 'name'>
    ), packs: Array<(
      { __typename?: 'ReceiptPack' }
      & { scanEvent?: Maybe<(
        { __typename?: 'ReceiptScanEvent' }
        & Pick<ReceiptScanEvent, 'id' | 'dateSubmitted'>
        & { scannedBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'givenName'>
        ) }
      )> }
      & PackInfoFragment
    )> }
  )> }
);

export type PackInfoFragment = (
  { __typename?: 'ReceiptPack' }
  & Pick<ReceiptPack, 'id' | 'dateCreated' | 'dateTransitioned' | 'status' | 'packNumber' | 'purchaseOrderNumber' | 'kilnDryDate' | 'productCode' | 'supplierProductCode' | 'sendable' | 'pieceCount'>
  & { tally: Array<(
    { __typename?: 'ReceiptPackTally' }
    & Pick<ReceiptPackTally, 'pieces' | 'length'>
  )> }
);

export type DeletePacksMutationVariables = Exact<{
  input: ReceiptDeletePacksInput;
}>;


export type DeletePacksMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptDeletePacksPayload' }
    & { userErrors: Array<(
      { __typename: 'ReceiptDeletePacksNotFoundError' }
      & Pick<ReceiptDeletePacksNotFoundError, 'message'>
    )> }
  ) }
);

export type SetPieceCountMutationVariables = Exact<{
  input: ReceiptPackSetPieceCountInput;
}>;


export type SetPieceCountMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptPackSetPieceCountPayload' }
    & { pack?: Maybe<(
      { __typename?: 'ReceiptPack' }
      & Pick<ReceiptPack, 'id' | 'sendable' | 'pieceCount'>
      & { tally: Array<(
        { __typename?: 'ReceiptPackTally' }
        & Pick<ReceiptPackTally, 'pieces' | 'length'>
      )> }
    )>, userErrors: Array<(
      { __typename: 'ReceiptPackSetPieceCountInvalidInputError' }
      & Pick<ReceiptPackSetPieceCountInvalidInputError, 'message'>
    ) | (
      { __typename: 'ReceiptPackSetPieceCountNotFoundError' }
      & Pick<ReceiptPackSetPieceCountNotFoundError, 'message'>
    ) | (
      { __typename: 'ReceiptPackSetPieceCountProductKindError' }
      & Pick<ReceiptPackSetPieceCountProductKindError, 'message'>
    )> }
  ) }
);

export type ReplaceTallyMutationVariables = Exact<{
  input: ReceiptPackReplaceTallyInput;
}>;


export type ReplaceTallyMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptPackReplaceTallyPayload' }
    & { pack?: Maybe<(
      { __typename?: 'ReceiptPack' }
      & Pick<ReceiptPack, 'id' | 'sendable'>
      & { tally: Array<(
        { __typename?: 'ReceiptPackTally' }
        & Pick<ReceiptPackTally, 'length' | 'pieces'>
      )> }
    )>, userErrors: Array<(
      { __typename: 'ReceiptPackReplaceTallyInvalidInputError' }
      & Pick<ReceiptPackReplaceTallyInvalidInputError, 'message'>
    ) | (
      { __typename: 'ReceiptPackReplaceTallyNotFoundError' }
      & Pick<ReceiptPackReplaceTallyNotFoundError, 'message'>
    ) | (
      { __typename: 'ReceiptPackReplaceTallyProductKindError' }
      & Pick<ReceiptPackReplaceTallyProductKindError, 'message'>
    )> }
  ) }
);

export type UpdatePurchaseOrderNumberMutationVariables = Exact<{
  input: ReceiptPacksUpdatePurchaseOrderNumberInput;
}>;


export type UpdatePurchaseOrderNumberMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptPacksUpdatePurchaseOrderNumberPayload' }
    & { packs?: Maybe<Array<(
      { __typename?: 'ReceiptPack' }
      & Pick<ReceiptPack, 'id' | 'sendable' | 'purchaseOrderNumber'>
    )>>, userErrors: Array<(
      { __typename: 'ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError' }
      & Pick<ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError, 'message'>
    ) | (
      { __typename: 'ReceiptPacksUpdatePurchaseOrderNumberNotFoundError' }
      & Pick<ReceiptPacksUpdatePurchaseOrderNumberNotFoundError, 'message'>
    )> }
  ) }
);

export type UpdateKilnDryDateMutationVariables = Exact<{
  input: ReceiptPackUpdateKilnDryDateInput;
}>;


export type UpdateKilnDryDateMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptPackUpdateKilnDryDatePayload' }
    & { packs?: Maybe<Array<(
      { __typename?: 'ReceiptPack' }
      & Pick<ReceiptPack, 'id' | 'sendable' | 'kilnDryDate'>
    )>>, userErrors: Array<(
      { __typename: 'ReceiptPackUpdateKilnDryDateNotFoundError' }
      & Pick<ReceiptPackUpdateKilnDryDateNotFoundError, 'message'>
    )> }
  ) }
);

export type UpdateProductCodeMutationVariables = Exact<{
  input: ReceiptPackUpdateProductCodeInput;
}>;


export type UpdateProductCodeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptPackUpdateProductCodePayload' }
    & { pack?: Maybe<(
      { __typename?: 'ReceiptPack' }
      & Pick<ReceiptPack, 'id' | 'sendable' | 'productCode' | 'pieceCount'>
    )>, userErrors: Array<(
      { __typename: 'ReceiptPackUpdateProductCodeInvalidInputError' }
      & Pick<ReceiptPackUpdateProductCodeInvalidInputError, 'message'>
    ) | (
      { __typename: 'ReceiptPackUpdateProductCodeNotFoundError' }
      & Pick<ReceiptPackUpdateProductCodeNotFoundError, 'message'>
    )> }
  ) }
);

export type SendPacksMutationVariables = Exact<{
  input: ReceiptSendPacksInput;
}>;


export type SendPacksMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptSendPacksPayload' }
    & { packs?: Maybe<Array<(
      { __typename?: 'ReceiptPack' }
      & Pick<ReceiptPack, 'id' | 'dateTransitioned' | 'sendable' | 'status'>
    )>>, userErrors: Array<(
      { __typename: 'ReceiptSendPacksNotFoundError' }
      & Pick<ReceiptSendPacksNotFoundError, 'message'>
    ) | (
      { __typename: 'ReceiptSendPacksNotSendableError' }
      & Pick<ReceiptSendPacksNotSendableError, 'message'>
    ) | (
      { __typename: 'ReceiptSendPacksSendingFailedError' }
      & Pick<ReceiptSendPacksSendingFailedError, 'message'>
    )> }
  ) }
);

export type UpdateUploadDateDispatchedMutationVariables = Exact<{
  input: ReceiptUploadUpdateDateDispatchedInput;
}>;


export type UpdateUploadDateDispatchedMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptUploadUpdateDateDispatchedPayload' }
    & { uploadEvent?: Maybe<(
      { __typename?: 'ReceiptUploadEvent' }
      & Pick<ReceiptUploadEvent, 'id' | 'dateDispatched'>
    )>, userErrors: Array<(
      { __typename: 'ReceiptUploadUpdateDateDispatchedNotFoundError' }
      & Pick<ReceiptUploadUpdateDateDispatchedNotFoundError, 'message'>
    )> }
  ) }
);

export type UpdateUploadReferenceMutationVariables = Exact<{
  input: ReceiptUploadUpdateReferenceInput;
}>;


export type UpdateUploadReferenceMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptUploadUpdateReferencePayload' }
    & { uploadEvent?: Maybe<(
      { __typename?: 'ReceiptUploadEvent' }
      & Pick<ReceiptUploadEvent, 'id' | 'reference'>
      & { packs: Array<(
        { __typename?: 'ReceiptPack' }
        & Pick<ReceiptPack, 'id' | 'sendable'>
      )> }
    )>, userErrors: Array<(
      { __typename: 'ReceiptUploadUpdateReferenceInvalidInputError' }
      & Pick<ReceiptUploadUpdateReferenceInvalidInputError, 'message'>
    ) | (
      { __typename: 'ReceiptUploadUpdateReferenceNotFoundError' }
      & Pick<ReceiptUploadUpdateReferenceNotFoundError, 'message'>
    )> }
  ) }
);

export type CreateManualMutationVariables = Exact<{
  input: ReceiptCreateManualInput;
}>;


export type CreateManualMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ReceiptCreateManualPayload' }
    & { userErrors: Array<(
      { __typename: 'ReceiptCreateManualConflictingPacksError' }
      & Pick<ReceiptCreateManualConflictingPacksError, 'message'>
    ) | (
      { __typename: 'ReceiptCreateManualInvalidInputError' }
      & Pick<ReceiptCreateManualInvalidInputError, 'message'>
    ) | (
      { __typename: 'ReceiptCreateManualNotFoundError' }
      & Pick<ReceiptCreateManualNotFoundError, 'message'>
    )> }
  ) }
);

export const PackInfoFragmentDoc = gql`
    fragment PackInfo on ReceiptPack {
  id
  dateCreated
  dateTransitioned
  status
  packNumber
  purchaseOrderNumber
  kilnDryDate
  productCode
  supplierProductCode
  sendable
  pieceCount
  tally {
    pieces
    length
  }
}
    `;
export const PendingPacksDocument = gql`
    query PendingPacks {
  uploaded: receiptUploadEvents(status: INCOMPLETE) {
    id
    type
    dateSubmitted
    dateDispatched
    reference
    supplier {
      id
      name
    }
    packs {
      ...PackInfo
      scanEvent {
        id
        scannedBy {
          id
          givenName
        }
      }
    }
  }
  scanned: receiptPacks(type: SCANNED) {
    ...PackInfo
    scanEvent {
      id
      dateSubmitted
      reference
      supplier {
        id
        name
      }
      scannedBy {
        id
        givenName
      }
    }
  }
}
    ${PackInfoFragmentDoc}`;

/**
 * __usePendingPacksQuery__
 *
 * To run a query within a React component, call `usePendingPacksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingPacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingPacksQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingPacksQuery(baseOptions?: Apollo.QueryHookOptions<PendingPacksQuery, PendingPacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingPacksQuery, PendingPacksQueryVariables>(PendingPacksDocument, options);
      }
export function usePendingPacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingPacksQuery, PendingPacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingPacksQuery, PendingPacksQueryVariables>(PendingPacksDocument, options);
        }
export type PendingPacksQueryHookResult = ReturnType<typeof usePendingPacksQuery>;
export type PendingPacksLazyQueryHookResult = ReturnType<typeof usePendingPacksLazyQuery>;
export type PendingPacksQueryResult = Apollo.QueryResult<PendingPacksQuery, PendingPacksQueryVariables>;
export const ProductCodesDocument = gql`
    query ProductCodes($purchaseOrderIds: [ID!]!) {
  packPurchaseOrders(ids: $purchaseOrderIds) {
    id
    items {
      id
      productCode
    }
  }
}
    `;

/**
 * __useProductCodesQuery__
 *
 * To run a query within a React component, call `useProductCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCodesQuery({
 *   variables: {
 *      purchaseOrderIds: // value for 'purchaseOrderIds'
 *   },
 * });
 */
export function useProductCodesQuery(baseOptions: Apollo.QueryHookOptions<ProductCodesQuery, ProductCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCodesQuery, ProductCodesQueryVariables>(ProductCodesDocument, options);
      }
export function useProductCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCodesQuery, ProductCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCodesQuery, ProductCodesQueryVariables>(ProductCodesDocument, options);
        }
export type ProductCodesQueryHookResult = ReturnType<typeof useProductCodesQuery>;
export type ProductCodesLazyQueryHookResult = ReturnType<typeof useProductCodesLazyQuery>;
export type ProductCodesQueryResult = Apollo.QueryResult<ProductCodesQuery, ProductCodesQueryVariables>;
export const MatchedPacksDocument = gql`
    query MatchedPacks($take: Int!) {
  matched: receiptUploadEvents(status: COMPLETE, take: $take) {
    id
    type
    dateSubmitted
    dateDispatched
    reference
    supplier {
      id
      name
    }
    packs {
      ...PackInfo
      scanEvent {
        id
        dateSubmitted
        scannedBy {
          id
          givenName
        }
      }
    }
  }
}
    ${PackInfoFragmentDoc}`;

/**
 * __useMatchedPacksQuery__
 *
 * To run a query within a React component, call `useMatchedPacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchedPacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchedPacksQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMatchedPacksQuery(baseOptions: Apollo.QueryHookOptions<MatchedPacksQuery, MatchedPacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MatchedPacksQuery, MatchedPacksQueryVariables>(MatchedPacksDocument, options);
      }
export function useMatchedPacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchedPacksQuery, MatchedPacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MatchedPacksQuery, MatchedPacksQueryVariables>(MatchedPacksDocument, options);
        }
export type MatchedPacksQueryHookResult = ReturnType<typeof useMatchedPacksQuery>;
export type MatchedPacksLazyQueryHookResult = ReturnType<typeof useMatchedPacksLazyQuery>;
export type MatchedPacksQueryResult = Apollo.QueryResult<MatchedPacksQuery, MatchedPacksQueryVariables>;
export const DeletePacksDocument = gql`
    mutation DeletePacks($input: ReceiptDeletePacksInput!) {
  result: receiptDeletePacks(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type DeletePacksMutationFn = Apollo.MutationFunction<DeletePacksMutation, DeletePacksMutationVariables>;

/**
 * __useDeletePacksMutation__
 *
 * To run a mutation, you first call `useDeletePacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePacksMutation, { data, loading, error }] = useDeletePacksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePacksMutation(baseOptions?: Apollo.MutationHookOptions<DeletePacksMutation, DeletePacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePacksMutation, DeletePacksMutationVariables>(DeletePacksDocument, options);
      }
export type DeletePacksMutationHookResult = ReturnType<typeof useDeletePacksMutation>;
export type DeletePacksMutationResult = Apollo.MutationResult<DeletePacksMutation>;
export type DeletePacksMutationOptions = Apollo.BaseMutationOptions<DeletePacksMutation, DeletePacksMutationVariables>;
export const SetPieceCountDocument = gql`
    mutation SetPieceCount($input: ReceiptPackSetPieceCountInput!) {
  result: receiptPackSetPieceCount(input: $input) {
    pack {
      id
      sendable
      pieceCount
      tally {
        pieces
        length
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type SetPieceCountMutationFn = Apollo.MutationFunction<SetPieceCountMutation, SetPieceCountMutationVariables>;

/**
 * __useSetPieceCountMutation__
 *
 * To run a mutation, you first call `useSetPieceCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPieceCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPieceCountMutation, { data, loading, error }] = useSetPieceCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPieceCountMutation(baseOptions?: Apollo.MutationHookOptions<SetPieceCountMutation, SetPieceCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPieceCountMutation, SetPieceCountMutationVariables>(SetPieceCountDocument, options);
      }
export type SetPieceCountMutationHookResult = ReturnType<typeof useSetPieceCountMutation>;
export type SetPieceCountMutationResult = Apollo.MutationResult<SetPieceCountMutation>;
export type SetPieceCountMutationOptions = Apollo.BaseMutationOptions<SetPieceCountMutation, SetPieceCountMutationVariables>;
export const ReplaceTallyDocument = gql`
    mutation ReplaceTally($input: ReceiptPackReplaceTallyInput!) {
  result: receiptPackReplaceTally(input: $input) {
    pack {
      id
      sendable
      tally {
        length
        pieces
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type ReplaceTallyMutationFn = Apollo.MutationFunction<ReplaceTallyMutation, ReplaceTallyMutationVariables>;

/**
 * __useReplaceTallyMutation__
 *
 * To run a mutation, you first call `useReplaceTallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTallyMutation, { data, loading, error }] = useReplaceTallyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceTallyMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceTallyMutation, ReplaceTallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceTallyMutation, ReplaceTallyMutationVariables>(ReplaceTallyDocument, options);
      }
export type ReplaceTallyMutationHookResult = ReturnType<typeof useReplaceTallyMutation>;
export type ReplaceTallyMutationResult = Apollo.MutationResult<ReplaceTallyMutation>;
export type ReplaceTallyMutationOptions = Apollo.BaseMutationOptions<ReplaceTallyMutation, ReplaceTallyMutationVariables>;
export const UpdatePurchaseOrderNumberDocument = gql`
    mutation UpdatePurchaseOrderNumber($input: ReceiptPacksUpdatePurchaseOrderNumberInput!) {
  result: receiptPacksUpdatePurchaseOrderNumber(input: $input) {
    packs {
      id
      sendable
      purchaseOrderNumber
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdatePurchaseOrderNumberMutationFn = Apollo.MutationFunction<UpdatePurchaseOrderNumberMutation, UpdatePurchaseOrderNumberMutationVariables>;

/**
 * __useUpdatePurchaseOrderNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseOrderNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseOrderNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseOrderNumberMutation, { data, loading, error }] = useUpdatePurchaseOrderNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchaseOrderNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseOrderNumberMutation, UpdatePurchaseOrderNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurchaseOrderNumberMutation, UpdatePurchaseOrderNumberMutationVariables>(UpdatePurchaseOrderNumberDocument, options);
      }
export type UpdatePurchaseOrderNumberMutationHookResult = ReturnType<typeof useUpdatePurchaseOrderNumberMutation>;
export type UpdatePurchaseOrderNumberMutationResult = Apollo.MutationResult<UpdatePurchaseOrderNumberMutation>;
export type UpdatePurchaseOrderNumberMutationOptions = Apollo.BaseMutationOptions<UpdatePurchaseOrderNumberMutation, UpdatePurchaseOrderNumberMutationVariables>;
export const UpdateKilnDryDateDocument = gql`
    mutation UpdateKilnDryDate($input: ReceiptPackUpdateKilnDryDateInput!) {
  result: receiptPackUpdateKilnDryDate(input: $input) {
    packs {
      id
      sendable
      kilnDryDate
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdateKilnDryDateMutationFn = Apollo.MutationFunction<UpdateKilnDryDateMutation, UpdateKilnDryDateMutationVariables>;

/**
 * __useUpdateKilnDryDateMutation__
 *
 * To run a mutation, you first call `useUpdateKilnDryDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKilnDryDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKilnDryDateMutation, { data, loading, error }] = useUpdateKilnDryDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKilnDryDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKilnDryDateMutation, UpdateKilnDryDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKilnDryDateMutation, UpdateKilnDryDateMutationVariables>(UpdateKilnDryDateDocument, options);
      }
export type UpdateKilnDryDateMutationHookResult = ReturnType<typeof useUpdateKilnDryDateMutation>;
export type UpdateKilnDryDateMutationResult = Apollo.MutationResult<UpdateKilnDryDateMutation>;
export type UpdateKilnDryDateMutationOptions = Apollo.BaseMutationOptions<UpdateKilnDryDateMutation, UpdateKilnDryDateMutationVariables>;
export const UpdateProductCodeDocument = gql`
    mutation UpdateProductCode($input: ReceiptPackUpdateProductCodeInput!) {
  result: receiptPackUpdateProductCode(input: $input) {
    pack {
      id
      sendable
      productCode
      pieceCount
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdateProductCodeMutationFn = Apollo.MutationFunction<UpdateProductCodeMutation, UpdateProductCodeMutationVariables>;

/**
 * __useUpdateProductCodeMutation__
 *
 * To run a mutation, you first call `useUpdateProductCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCodeMutation, { data, loading, error }] = useUpdateProductCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCodeMutation, UpdateProductCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCodeMutation, UpdateProductCodeMutationVariables>(UpdateProductCodeDocument, options);
      }
export type UpdateProductCodeMutationHookResult = ReturnType<typeof useUpdateProductCodeMutation>;
export type UpdateProductCodeMutationResult = Apollo.MutationResult<UpdateProductCodeMutation>;
export type UpdateProductCodeMutationOptions = Apollo.BaseMutationOptions<UpdateProductCodeMutation, UpdateProductCodeMutationVariables>;
export const SendPacksDocument = gql`
    mutation SendPacks($input: ReceiptSendPacksInput!) {
  result: receiptSendPacks(input: $input) {
    packs {
      id
      dateTransitioned
      sendable
      status
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type SendPacksMutationFn = Apollo.MutationFunction<SendPacksMutation, SendPacksMutationVariables>;

/**
 * __useSendPacksMutation__
 *
 * To run a mutation, you first call `useSendPacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPacksMutation, { data, loading, error }] = useSendPacksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPacksMutation(baseOptions?: Apollo.MutationHookOptions<SendPacksMutation, SendPacksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPacksMutation, SendPacksMutationVariables>(SendPacksDocument, options);
      }
export type SendPacksMutationHookResult = ReturnType<typeof useSendPacksMutation>;
export type SendPacksMutationResult = Apollo.MutationResult<SendPacksMutation>;
export type SendPacksMutationOptions = Apollo.BaseMutationOptions<SendPacksMutation, SendPacksMutationVariables>;
export const UpdateUploadDateDispatchedDocument = gql`
    mutation UpdateUploadDateDispatched($input: ReceiptUploadUpdateDateDispatchedInput!) {
  result: receiptUploadUpdateDateDispatched(input: $input) {
    uploadEvent {
      id
      dateDispatched
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdateUploadDateDispatchedMutationFn = Apollo.MutationFunction<UpdateUploadDateDispatchedMutation, UpdateUploadDateDispatchedMutationVariables>;

/**
 * __useUpdateUploadDateDispatchedMutation__
 *
 * To run a mutation, you first call `useUpdateUploadDateDispatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUploadDateDispatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUploadDateDispatchedMutation, { data, loading, error }] = useUpdateUploadDateDispatchedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUploadDateDispatchedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUploadDateDispatchedMutation, UpdateUploadDateDispatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUploadDateDispatchedMutation, UpdateUploadDateDispatchedMutationVariables>(UpdateUploadDateDispatchedDocument, options);
      }
export type UpdateUploadDateDispatchedMutationHookResult = ReturnType<typeof useUpdateUploadDateDispatchedMutation>;
export type UpdateUploadDateDispatchedMutationResult = Apollo.MutationResult<UpdateUploadDateDispatchedMutation>;
export type UpdateUploadDateDispatchedMutationOptions = Apollo.BaseMutationOptions<UpdateUploadDateDispatchedMutation, UpdateUploadDateDispatchedMutationVariables>;
export const UpdateUploadReferenceDocument = gql`
    mutation UpdateUploadReference($input: ReceiptUploadUpdateReferenceInput!) {
  result: receiptUploadUpdateReference(input: $input) {
    uploadEvent {
      id
      reference
      packs {
        id
        sendable
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdateUploadReferenceMutationFn = Apollo.MutationFunction<UpdateUploadReferenceMutation, UpdateUploadReferenceMutationVariables>;

/**
 * __useUpdateUploadReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateUploadReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUploadReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUploadReferenceMutation, { data, loading, error }] = useUpdateUploadReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUploadReferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUploadReferenceMutation, UpdateUploadReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUploadReferenceMutation, UpdateUploadReferenceMutationVariables>(UpdateUploadReferenceDocument, options);
      }
export type UpdateUploadReferenceMutationHookResult = ReturnType<typeof useUpdateUploadReferenceMutation>;
export type UpdateUploadReferenceMutationResult = Apollo.MutationResult<UpdateUploadReferenceMutation>;
export type UpdateUploadReferenceMutationOptions = Apollo.BaseMutationOptions<UpdateUploadReferenceMutation, UpdateUploadReferenceMutationVariables>;
export const CreateManualDocument = gql`
    mutation CreateManual($input: ReceiptCreateManualInput!) {
  result: receiptCreateManual(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreateManualMutationFn = Apollo.MutationFunction<CreateManualMutation, CreateManualMutationVariables>;

/**
 * __useCreateManualMutation__
 *
 * To run a mutation, you first call `useCreateManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualMutation, { data, loading, error }] = useCreateManualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualMutation, CreateManualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualMutation, CreateManualMutationVariables>(CreateManualDocument, options);
      }
export type CreateManualMutationHookResult = ReturnType<typeof useCreateManualMutation>;
export type CreateManualMutationResult = Apollo.MutationResult<CreateManualMutation>;
export type CreateManualMutationOptions = Apollo.BaseMutationOptions<CreateManualMutation, CreateManualMutationVariables>;