import {
  Typography,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import {
  PendingPacksQuery,
  useCreateManualMutation,
  useDeletePacksMutation,
} from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';
import { PackRow } from './PackRow';
import { CardStyle } from './CardStyle';

type Scan = NonNullable<PendingPacksQuery['scanned'][0]['scanEvent']>;
type ScannedPack = PendingPacksQuery['scanned'][0];

const empty: never[] = [];

export const ScannedGroup: React.FC<{ scan: Scan; packs: ScannedPack[] }> = ({
  scan,
  packs,
}) => {
  const packIds = packs.map((x) => x.id);
  const createManual = useCreateManual(scan.supplier.id, packIds);

  const deletePacks = useDeletePacks();
  const deletePack = useCallback(
    (packId: string) => {
      return deletePacks([packId], false, false);
    },
    [deletePacks],
  );

  return (
    <CardStyle className="scan">
      <div className="header">
        <div className="header-left">
          <Typography style={{ fontSize: '1.4rem', fontWeight: 500 }}>
            SCAN
          </Typography>
          <Typography variant="h5">{scan.supplier.name}</Typography>
        </div>
        <Typography>
          <strong>Submitted:</strong>{' '}
          {format(new Date(scan.dateSubmitted), 'dd.MM.yyyy')}
        </Typography>
        <Typography>
          <Tooltip title="Can only modify this field on uploads">
            <span>
              <strong>Reference:</strong> {scan.reference}
            </span>
          </Tooltip>
        </Typography>
        <Typography>
          <strong>Scanned By:</strong> {scan.scannedBy.givenName}
        </Typography>
        <div style={{ width: 130, marginRight: '-1.7em' }}>
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            onClick={createManual}
          >
            Add Details
          </Button>
        </div>
        <IconButton
          style={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => deletePacks(packIds, true, false)}
          size="large"
        >
          <Close />
        </IconButton>
      </div>
      <Table>
        <TableHead className="table-header scan">
          <TableRow>
            <TableCell>Pack Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packs.map((pack) => (
            <PackRow
              key={pack.id}
              pack={pack}
              onDeletePack={deletePack}
              productCodeMappings={empty}
              scanGroup
            />
          ))}
        </TableBody>
      </Table>
    </CardStyle>
  );
};

export function useDeletePacks() {
  const [deletePacks] = useDeletePacksMutation();
  return async (packIds: string[], group: boolean, warning: boolean) => {
    const result = await Swal.fire({
      text: warning
        ? group
          ? 'Are you sure? Some packs are marked as "sending", but may actually be in TimberSmart.'
          : 'Are you sure? This pack is marked as "sending", but may actually be in TimberSmart.'
        : 'Are you sure?',
      showCancelButton: true,
    });
    if (!result.isConfirmed) return;

    const promise = deletePacks({
      variables: {
        input: { packIds },
      },
      refetchQueries: ['PendingPacks'],
      awaitRefetchQueries: true,
    });

    displayUserErrors('result', promise, true);
  };
}

export function useCreateManual(supplierId: string, packIds: string[]) {
  const [create] = useCreateManualMutation({
    variables: {
      input: {
        supplierId,
        packIds,
      },
    },
    refetchQueries: ['PendingPacks'],
    awaitRefetchQueries: true,
  });

  return async () => {
    const result = await Swal.fire({
      text: 'Are you sure? This will prevent delivery dockets that contain these packs from being uploaded.',
      showCancelButton: true,
    });
    if (!result.isConfirmed) return;

    await displayUserErrors('result', create(), true);

    // Scroll to the top of the page because that's where the created upload
    // event will go (it's the newest one).
    // Without a delay the Swal popup makes `scrollTo` buggy
    await new Promise((res) => setTimeout(res, 300));
    document.getElementById('content-wrapper')?.scrollTo({ top: 0 });
  };
}
