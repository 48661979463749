import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 64px;
  height: calc(100vh - 64px);
  overflow-y: auto;

  display: flex;
  justify-content: center;

  & > div {
    padding: 2em;
    margin-bottom: 1em;
  }
`;

export const ContentWrapper: React.FC = ({ children }) => (
  <Wrapper id="content-wrapper">
    <div>
      {children}
      <div style={{ height: 100 }}></div>
    </div>
  </Wrapper>
);
