import { ContentWrapper } from './ContentWrapper';
import { Container } from '@mui/material';
import { usePendingPacksQuery } from '../generated/graphql';
import { Loading } from './Loading';
import { Error as ShowError } from './Error';
import { PAGE_POLL_INTERVAL } from '../constants';
import { allPurchaseOrders, toLookup, useProductCodes } from '../utils';
import { UploadGroup } from './UploadGroup';
import { ScannedGroup } from './ScannedGroup';

export const PendingPacksPage: React.FC = () => {
  return (
    <ContentWrapper>
      <Container style={{ width: 1100, marginBottom: '2em' }}>
        <PendingPacks />
      </Container>
    </ContentWrapper>
  );
};

const PendingPacks: React.FC = () => {
  const { data, loading, error } = usePendingPacksQuery({
    pollInterval: PAGE_POLL_INTERVAL,
  });

  const productCodes = useProductCodes(
    data == null ? undefined : allPurchaseOrders(data),
  );

  if (loading) return <Loading />;
  if (error || !data) return <ShowError />;

  if (data.scanned.some((x) => x.scanEvent == null)) {
    console.error('`scanEvent` null on some scanned packs');
    return <ShowError errorText="Invariant broken. Please contact IT." />;
  }

  const scanEvents = new Map(
    data.scanned.map((x) => [x.scanEvent!.id, x.scanEvent]),
  );
  const scanned = toLookup(data.scanned, (x) => x.scanEvent!.id);

  return (
    <>
      {data.uploaded.length === 0 && data.scanned.length === 0 && (
        <div style={{ textAlign: 'center' }}>
          He said "let there be packs." But alas there were none.
        </div>
      )}

      {data.uploaded.map((upload) => (
        <UploadGroup
          key={upload.id}
          upload={upload}
          productCodes={productCodes}
          frozen={false}
        />
      ))}

      {[...scanEvents.keys()].map((eventId) => {
        const packs = scanned.get(eventId);
        if (packs?.[0] == null) {
          throw new Error('Invariant broken');
        }

        const scan = packs[0].scanEvent!;
        return <ScannedGroup key={eventId} scan={scan} packs={packs} />;
      })}
    </>
  );
};
