import { useSpring, animated } from 'react-spring';

export interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

export const Fade: React.FC<FadeProps> = ({
  children,
  in: open,
  onEnter,
  onExited,
}) => {
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return <animated.div style={style}>{children}</animated.div>;
};
