import { useUpdateKilnDryDateMutation } from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';
import { DateField } from './DateField';

export const KilnDryDate: React.FC<{
  packId: string;
  sendable: boolean;
  date: string | null;
}> = ({ packId, sendable, date }) => {
  const [mutate] = useUpdateKilnDryDateMutation();

  return (
    <DateField
      date={date}
      mutate={(date) => {
        const promise = mutate({
          variables: {
            input: {
              packIds: [packId],
              kilnDryDate: date,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            result: {
              __typename: 'ReceiptPackUpdateKilnDryDatePayload',
              packs: [
                {
                  __typename: 'ReceiptPack',
                  id: packId,
                  sendable,
                  kilnDryDate: date,
                },
              ],
              userErrors: [],
            },
          },
        });

        displayUserErrors('result', promise, true);
      }}
    />
  );
};
