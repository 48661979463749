import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import Close from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { useCallback } from 'react';
import {
  PendingPacksQuery,
  ReceiptPackStatus,
  ReceiptUploadEventType,
  useUpdateUploadReferenceMutation,
} from '../generated/graphql';
import { ProductCodesMap } from '../utils';
import { displayUserErrors } from '../utils/errors';
import { BulkEditKilnDryDates } from './BulkEditKilnDryDates';
import { BulkEditPurchaseOrderNumbers } from './BulkEditPurchaseOrderNumbers';

import { DispatchDate } from './DispatchDate';
import { EditableDisplay } from './EditableDisplay';
import { PackRow } from './PackRow';
import { useDeletePacks } from './ScannedGroup';
import { CardStyle } from './CardStyle';

const empty: never[] = [];

type Upload = PendingPacksQuery['uploaded'][0];

export const UploadGroup: React.FC<{
  upload: Upload;
  productCodes: ProductCodesMap;
  frozen: boolean;
}> = ({ upload, productCodes, frozen }) => {
  const deletePacks = useDeletePacks();
  const deletePack = useCallback(
    (packId: string) => {
      const pack = upload.packs.find((x) => x.id === packId);
      if (pack == null) return;
      deletePacks([packId], false, pack.status === ReceiptPackStatus.Sending);
    },
    [deletePacks, upload.packs],
  );

  const setReference = useUpdateReference(upload);

  const liftHeaderForButtonStyle = frozen
    ? undefined
    : { display: 'block', transform: 'translateY(-10px)' };

  const packSummary = upload.packs.map((x) => ({
    id: x.id,
    status: x.status,
    sendable: x.sendable,
  }));

  const deleteEnabled =
    !frozen &&
    upload.packs.every(
      (x) =>
        x.status === ReceiptPackStatus.New ||
        x.status === ReceiptPackStatus.Sending,
    );
  const deleteWarning = upload.packs.some(
    (x) => x.status === ReceiptPackStatus.Sending,
  );

  const allPackIds = upload.packs.map((x) => x.id);

  return (
    <CardStyle className="upload">
      <div className="header">
        <div className="header-left">
          <Typography style={{ fontSize: '1.4rem', fontWeight: 500 }}>
            {upload.type === ReceiptUploadEventType.Upload && 'UPLOAD'}
            {upload.type === ReceiptUploadEventType.Manual && 'MANUAL'}
          </Typography>
          <Typography variant="h5">{upload.supplier.name}</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', width: 140 }}>
          <Typography>
            <strong style={{ marginRight: '5px' }}>Reference:</strong>
          </Typography>
          <EditableDisplay
            editable={!frozen}
            save={setReference}
            value={upload.reference}
            style={{ width: 80, whiteSpace: 'nowrap' }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ marginRight: 10 }}>
            <strong>Date Dispatched:</strong>
          </Typography>
          {frozen ? (
            upload.dateDispatched != null &&
            format(new Date(upload.dateDispatched), 'dd.MM.yyyy')
          ) : (
            <DispatchDate
              uploadEventId={upload.id}
              date={upload.dateDispatched ?? null}
            />
          )}
        </div>
        {deleteEnabled && (
          <IconButton
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={() => deletePacks(allPackIds, true, deleteWarning)}
            size="large"
          >
            <Close />
          </IconButton>
        )}
      </div>
      <Table className="table">
        <TableHead className="table-header">
          <TableRow>
            <TableCell>Scanned By</TableCell>
            <TableCell>Pack Number</TableCell>
            <TableCell>
              <div style={{ lineHeight: '0.9rem' }}>Our Product Code</div>
              <div style={{ lineHeight: '0.9rem', fontSize: '0.7rem' }}>
                Supplier Product Code
              </div>
            </TableCell>
            <TableCell style={{ position: 'relative' }}>
              <span style={liftHeaderForButtonStyle}>Kiln Dry Date</span>
              {!frozen && <BulkEditKilnDryDates packs={packSummary} />}
            </TableCell>
            <TableCell style={{ position: 'relative' }}>
              <span style={liftHeaderForButtonStyle}>PO#</span>
              {!frozen && <BulkEditPurchaseOrderNumbers packs={packSummary} />}
            </TableCell>
            <TableCell>Tally</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {upload.packs.map((pack) => (
            <PackRow
              key={pack.id}
              pack={pack}
              onDeletePack={deletePack}
              productCodeMappings={
                productCodes.get(pack.purchaseOrderNumber!) ?? empty
              }
            />
          ))}
        </TableBody>
      </Table>
    </CardStyle>
  );
};

function useUpdateReference(upload: Upload) {
  const [mutate] = useUpdateUploadReferenceMutation();
  return (ref: string) => {
    return displayUserErrors(
      'result',
      mutate({
        variables: {
          input: {
            uploadEventId: upload.id,
            reference: ref,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          result: {
            __typename: 'ReceiptUploadUpdateReferencePayload',
            uploadEvent: {
              __typename: 'ReceiptUploadEvent',
              id: upload.id,
              reference: ref,
              packs: upload.packs.map((p) => ({
                __typename: p.__typename,
                id: p.id,
                sendable: p.sendable,
              })),
            },
            userErrors: [],
          },
        },
      }),
      true,
    );
  };
}
