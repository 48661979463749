import { useUpdateUploadDateDispatchedMutation } from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';
import { DateField } from './DateField';

export const DispatchDate: React.FC<{
  uploadEventId: string;
  date: string | null;
}> = ({ uploadEventId, date }) => {
  const [mutate] = useUpdateUploadDateDispatchedMutation();

  return (
    <DateField
      date={date}
      mutate={(date) => {
        const promise = mutate({
          variables: {
            input: {
              uploadEventId,
              dateDispatched: date,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            result: {
              __typename: 'ReceiptUploadUpdateDateDispatchedPayload',
              uploadEvent: {
                __typename: 'ReceiptUploadEvent',
                id: uploadEventId,
                dateDispatched: date,
              },
              userErrors: [],
            },
          },
        });

        displayUserErrors('result', promise, true);
      }}
    />
  );
};
