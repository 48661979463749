import React from 'react';
import { useSetPieceCountMutation } from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';
import { EditableDisplay } from './EditableDisplay';

export const ManagePieceCount: React.FC<{
  packId: string;
  pieceCount: number;
  sendable: boolean;
  readOnly: boolean;
}> = ({ packId, pieceCount, sendable, readOnly }) => {
  const [set] = useSetPieceCountMutation();
  const handleSetPieceCount = (value: string) => {
    const count = parseFloat(value);
    if (isNaN(count)) return;

    const promise = set({
      variables: {
        input: {
          packId,
          pieceCount: count,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        result: {
          __typename: 'ReceiptPackSetPieceCountPayload',
          pack: {
            __typename: 'ReceiptPack',
            id: packId,
            pieceCount: count,
            sendable,
            // Not visible to user (tally is hidden for set length product),
            // though this is wrong.We can't know what this should be any
            // faster than the server can tell us
            tally: [],
          },
          userErrors: [],
        },
      },
    });

    displayUserErrors('result', promise, true);
  };

  return (
    <div>
      <EditableDisplay
        editable={!readOnly}
        badMessage={
          !Number.isInteger(pieceCount)
            ? 'Piece count must be an integer!'
            : undefined
        }
        save={handleSetPieceCount}
        value={pieceCount.toString()}
        style={{ fontSize: '0.9rem' }}
        editStyle={{ width: 30 }}
        suffix=" PCE"
      />
    </div>
  );
};
