import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useState } from 'react';
import {
  ReceiptPackStatus,
  useUpdateKilnDryDateMutation,
} from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';
import { DateField } from './DateField';

export const BulkEditKilnDryDates: React.FC<{
  packs: Array<{
    id: string;
    status: ReceiptPackStatus;
    sendable: boolean;
  }>;
}> = ({ packs }) => {
  const [mutate] = useUpdateKilnDryDateMutation();
  const [kdd, setKdd] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSetKilnDryDates = async () => {
    const relevantPacks = packs.filter(
      (x) => x.status === ReceiptPackStatus.New,
    );

    const promise = mutate({
      variables: {
        input: {
          packIds: relevantPacks.map((x) => x.id),
          kilnDryDate: kdd,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        result: {
          __typename: 'ReceiptPackUpdateKilnDryDatePayload',
          packs: relevantPacks.map((p) => ({
            __typename: 'ReceiptPack',
            id: p.id,
            sendable: p.sendable,
            kilnDryDate: kdd,
          })),
          userErrors: [],
        },
      },
    });

    displayUserErrors('result', promise, true);
    setModalOpen(false);
  };

  return (
    <>
      <Button
        style={{ position: 'absolute', left: -2, bottom: 5, padding: '0.1em' }}
        onClick={() => {
          setKdd(null);
          setModalOpen(true);
        }}
      >
        Edit
      </Button>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Bulk modify Kiln Dry Dates</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a kiln dry date to set for the packs. Packs that have already
            been sent to the inventory system or are currently sending will not
            be modified.
          </DialogContentText>
          <DateField date={kdd} mutate={setKdd} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={() => handleSetKilnDryDates()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
