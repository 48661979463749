import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import source from '../../CHANGELOG.md';
import ReactMarkdown from 'react-markdown';
import { IconButton, Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { env, releaseProfile } from '../runtime-environment';

export const VersionNumber: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState('');

  useEffect(() => {
    fetch(source)
      .then((res) => res.text())
      .then((post) => setState(post))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    return () => {};
  }, []);

  return <>
    <VersionStyle>
      <Typography
        onClick={() => setOpen(true)}
        align="left"
        style={{ color: 'white' }}
      >
        {env.version} {releaseAnnotation()}
      </Typography>
    </VersionStyle>
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalStyle>
        <IconButton
          onClick={() => setOpen(false)}
          style={{
            position: 'absolute',
            top: '1em',
            right: '1em',
          }}
          size="large">
          <CloseIcon style={{ padding: 0 }} />
        </IconButton>
        <ReactMarkdown>{state}</ReactMarkdown>
      </ModalStyle>
    </Modal>
  </>;
};

function releaseAnnotation() {
  const profile = releaseProfile();
  if (profile === 'localhost') return '(local)';
  if (profile === 'staging') return '(staging)';
  if (profile === 'feat-eval') return '(feature eval)';
  return '';
}

const VersionStyle = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const ModalStyle = styled(Paper)`
  font-family: Roboto;
  overflow-y: auto;
  left: 15vw;
  top: 5vh;
  width: 70vw;
  height: 90vh;
  position: fixed;
  outline: none;
  padding: 2em;

  & * {
    padding: 0.3em;
    list-style-position: inside;
  }

  h2 {
    padding-top: 1.5em;
  }

  li {
    padding-left: 10px;
  }
`;
