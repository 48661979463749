import { ContentWrapper } from './ContentWrapper';
import { Container } from '@mui/material';
import { useMatchedPacksQuery } from '../generated/graphql';
import { Loading } from './Loading';
import { Error } from './Error';
import { MATCHED_EVENT_LIST_COUNT, PAGE_POLL_INTERVAL } from '../constants';
import { UploadGroup } from './UploadGroup';

export const MatchedPacksPage: React.FC = () => {
  return (
    <ContentWrapper>
      <Container style={{ width: 1100, marginBottom: '2em' }}>
        <MatchedPacks />
      </Container>
    </ContentWrapper>
  );
};

const empty = new Map<string, never>();

const MatchedPacks: React.FC = () => {
  const { data, loading, error } = useMatchedPacksQuery({
    variables: { take: MATCHED_EVENT_LIST_COUNT },
    pollInterval: PAGE_POLL_INTERVAL,
  });

  if (loading) return <Loading />;
  if (error || !data) return <Error />;

  return (
    <>
      {data.matched.length === 0 && (
        <div style={{ textAlign: 'center' }}>
          There's nothing here. Either that's kind of neat or really bad. If you
          think this is a mistake please create a support ticket.
        </div>
      )}
      {data.matched.map((event) => (
        <UploadGroup upload={event} productCodes={empty} frozen />
      ))}
    </>
  );
};
