import React from 'react';
import { TableCell, TableRow } from '@mui/material';

export const TallyRow: React.FC<{
  pieces: number;
  length: number;
}> = ({ pieces, length }) => {
  return (
    <TableRow hover>
      <TableCell
        align="center"
        style={{
          padding: 'none',
          background: '#d9d9d9a6',
          wordBreak: 'break-word',
        }}
      >
        {pieces}
      </TableCell>
      <TableCell style={{ wordBreak: 'break-word' }} align="center">
        {length}
      </TableCell>
    </TableRow>
  );
};

export default TallyRow;
