import { useState } from 'react';
import { format } from 'date-fns';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

const EARLIEST_DATE = new Date('2020-01-01').getTime();

export const DateField: React.FC<{
  mutate: (date: string | null) => void;
  date: string | null;
}> = ({ mutate, date }) => {
  const [error, setError] = useState(false);

  return (
    <DatePicker
      value={date != null ? new Date(date) : null}
      onChange={(value) => {
        // Unless we check against `EARLIEST_DATE`, we will perform a mutation
        // with the year "0020" for example, which fails, and makes filling in
        // dates really painful
        if (
          value != null &&
          (isNaN(value.getTime()) || value.getTime() < EARLIEST_DATE)
        ) {
          setError(true);
          return;
        }

        if (error) {
          setError(false);
        }

        const stringVal = value == null ? null : format(value, 'yyyy-MM-dd');
        mutate(stringVal);
      }}
      inputFormat="dd.MM.yyyy"
      disableFuture
      mask="__.__.____"
      renderInput={(props) => (
        <TextField
          variant="standard"
          {...props}
          error={error}
          style={{
            width: 130,
          }}
        />
      )}
    />
  );
};
