import { useEffect, useState } from 'react';
import { PendingPacksQuery, useProductCodesQuery } from '../generated/graphql';

export function toLookup<K, V>(values: V[], key: (x: V) => K): Map<K, V[]> {
  const map = new Map<K, V[]>();
  for (const v of values) {
    const k = key(v);
    const entry = map.get(k);
    if (!entry) {
      map.set(k, [v]);
    } else {
      entry.push(v);
    }
  }
  return map;
}

// Borrowed from Factory Planner
export function useNow(
  intervalMs: number,
  update?: (prev: Date, next: Date) => void,
) {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const sub = setTimeout(() => {
      const newDate = new Date();
      update?.(now, newDate);
      setNow(newDate);
    }, intervalMs);
    return () => {
      clearTimeout(sub);
    };
  }, [update, now, intervalMs]);
  return now;
}

export type ProductCodesMap = Map<string, string[]>;

export function useProductCodes(
  purchaseOrderIds: string[] | undefined,
): ProductCodesMap {
  const { data } = useProductCodesQuery({
    variables: { purchaseOrderIds: purchaseOrderIds! },
    skip: purchaseOrderIds == null,
  });

  if (!data) {
    return new Map();
  }

  return new Map(
    data.packPurchaseOrders.map((x) => [
      x.id,
      [...new Set(x.items.map((item) => item.productCode))],
    ]),
  );
}

export function allPurchaseOrders(data: PendingPacksQuery) {
  return [
    ...new Set(
      data.uploaded
        .flatMap((event) =>
          event.packs.map((pack) => pack.purchaseOrderNumber || null!),
        )
        .filter((x) => x != null),
    ),
  ];
}
