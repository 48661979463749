import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Button,
  TableRow,
  TableCell,
  Input,
  Backdrop,
  Tooltip,
  Table,
  TableBody,
  Dialog,
} from '@mui/material';
import { useReplaceTallyMutation } from '../generated/graphql';
import { Fade } from './Fade';
import TallyRow from './TallyRow';
import { displayUserErrors } from '../utils/errors';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      padding: theme.spacing(3, 4, 3, 3),
    },
  }),
);

export const ManageTally: React.FC<{
  packId: string;
  sendable: boolean;
  tally: Array<{ pieces: number; length: number }>;
  readOnly: boolean;
}> = ({ packId, sendable, tally, readOnly }) => {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [pieceState, setPieceState] = useState<string | null>(null);
  const [lengthState, setLengthState] = useState<string | null>(null);

  const [replace] = useReplaceTallyMutation();
  const handleAddTally = async () => {
    if (!pieceState || !lengthState) return;
    const pieces = parseInt(pieceState ?? '');
    const length = parseFloat(lengthState ?? '');

    const newTally = [...tally, { pieces, length }];
    const promise = replace({
      variables: {
        input: {
          packId,
          tally: newTally.map((x) => ({
            pieces: x.pieces,
            length: x.length,
          })),
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        result: {
          __typename: 'ReceiptPackReplaceTallyPayload',
          pack: {
            __typename: 'ReceiptPack',
            id: packId,
            sendable: sendable,
            tally: newTally.map((x) => ({
              __typename: 'ReceiptPackTally',
              pieces: x.pieces,
              length: x.length,
            })),
          },
          userErrors: [],
        },
      },
    });

    await displayUserErrors('result', promise, true);

    setPieceState('');
    setLengthState('');
  };

  const handleClearTally = () => {
    const promise = replace({
      variables: {
        input: {
          packId,
          tally: [],
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        result: {
          __typename: 'ReceiptPackReplaceTallyPayload',
          pack: {
            __typename: 'ReceiptPack',
            id: packId,
            sendable: sendable,
            tally: [],
          },
          userErrors: [],
        },
      },
    });

    displayUserErrors('result', promise, true);
  };

  const tallySummary = tally.map((x) => `${x.pieces}/${x.length}`).join(', ');

  const headings = (
    <TableRow>
      <TableCell>
        <Input
          placeholder="PCE"
          type="number"
          size="small"
          value={pieceState ?? ''}
          onChange={(e) => setPieceState(e.target.value || null)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddTally();
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Input
          placeholder="LEN"
          type="number"
          size="small"
          value={lengthState ?? ''}
          onChange={(e) => setLengthState(e.target.value || null)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddTally();
            }
          }}
        />
      </TableCell>
      <TableCell>
        <div className="buttons" style={{ display: 'flex' }}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleAddTally}
            disabled={!pieceState || !lengthState}
          >
            Add
          </Button>
          <Button
            variant="contained"
            disableElevation
            disabled={tally.length === 0}
            style={{ marginLeft: '1em' }}
            color="secondary"
            onClick={() => handleClearTally()}
          >
            Clear All
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );

  return (
    <div>
      <Tooltip title={tallySummary}>
        <Button
          variant="contained"
          disableElevation
          color={tally.length > 0 ? 'primary' : 'secondary'}
          onClick={() => setOpen(true)}
        >
          {tally.length > 0 ? (readOnly ? 'View' : 'View/Edit') : 'Add'}
        </Button>
      </Tooltip>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 200 }}
      >
        <div>
          <Fade in={open}>
            <div className={classes.paper}>
              <Table>
                <TableBody>
                  {!readOnly && headings}
                  {tally.map((t, idx) => (
                    <TallyRow key={idx} pieces={t.pieces} length={t.length} />
                  ))}
                </TableBody>
              </Table>
            </div>
          </Fade>
        </div>
      </Dialog>
    </div>
  );
};
