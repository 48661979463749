import * as Sentry from '@sentry/react';
import { Route, Switch } from 'react-router-dom';
import { ContentWrapper } from './ContentWrapper';
import { Error } from './Error';

import { MatchedPacksPage } from './MatchedPacksPage';
import { PendingPacksPage } from './PendingPacksPage';

const SentryRoute = Sentry.withSentryRouting(Route);

export const Routes: React.FC = () => (
  <Switch>
    <SentryRoute path="/matched-packs">
      <MatchedPacksPage />
    </SentryRoute>
    <SentryRoute path="/" exact>
      <PendingPacksPage />
    </SentryRoute>
    <SentryRoute path="*" exact>
      <ContentWrapper>
        <Error errorText="404. Hmm" />
      </ContentWrapper>
    </SentryRoute>
  </Switch>
);
