import { makeApolloClient } from './services/apollo';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { Router } from 'react-router-dom';
import { history } from './history';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { AppInner } from './components/AppInner';

const App: React.FC = () => (
  <ApolloProvider client={makeApolloClient()}>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={materialOverrides}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <CssBaseline />
            <Router history={history}>
              <AppInner />
            </Router>
          </LocalizationProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </ApolloProvider>
);

const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#375954',
    },
    secondary: {
      main: '#d3d3d3',
    },
    error: {
      main: '#d35f5f',
    },
    warning: {
      main: '#e9822e',
    },
  },
});

export default App;
