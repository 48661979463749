import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import {
  ReceiptPackStatus,
  useUpdatePurchaseOrderNumberMutation,
} from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';

export const BulkEditPurchaseOrderNumbers: React.FC<{
  packs: Array<{
    id: string;
    status: ReceiptPackStatus;
    sendable: boolean;
  }>;
}> = ({ packs }) => {
  const [mutate] = useUpdatePurchaseOrderNumberMutation();
  const [poNum, setPoNum] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleSetPoNumbers = async () => {
    const relevantPacks = packs.filter(
      (x) => x.status === ReceiptPackStatus.New,
    );

    const promise = mutate({
      variables: {
        input: {
          packIds: relevantPacks.map((x) => x.id),
          purchaseOrderNumber: poNum,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        result: {
          __typename: 'ReceiptPacksUpdatePurchaseOrderNumberPayload',
          packs: relevantPacks.map((p) => ({
            __typename: 'ReceiptPack',
            id: p.id,
            sendable: p.sendable,
            purchaseOrderNumber: poNum,
          })),
          userErrors: [],
        },
      },
    });

    displayUserErrors('result', promise, true);
    setModalOpen(false);
  };

  return (
    <>
      <Button
        style={{ position: 'absolute', left: -2, bottom: 5, padding: '0.1em' }}
        onClick={() => {
          setPoNum('');
          setModalOpen(true);
        }}
      >
        Edit
      </Button>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Bulk modify Purchase Order Numbers</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a purchase order number to set for the packs. Packs that have
            already been sent to the inventory system or are currently sending
            will not be modified.
          </DialogContentText>
          <TextField
            style={{ width: 200 }}
            placeholder="Purchase Order Number..."
            variant="standard"
            value={poNum}
            onChange={(e) => setPoNum(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={() => handleSetPoNumbers()}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
