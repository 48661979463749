import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';

import introspectionResult from '../../generated/introspection-result';
import { env } from '../../runtime-environment';

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    // Requests for this specific app can be found in Apollo Studio
    'apollographql-client-name': 'ts-addons',
    'apollographql-client-version': env.version || 'local',
    // "Authorization" on the VPN
    'x-client-app': 'ts-addons',
  },
}));

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {},
});

const sentryLink = new SentryLink({
  uri: env.serverUri,
  setTransaction: true,
  setFingerprint: true,
  attachBreadcrumbs: {},
});

let client: ApolloClient<NormalizedCacheObject> | null = null;
export const makeApolloClient = () => {
  if (client) return client;

  const httpLink = createHttpLink({
    uri: env.serverUri,
  });

  client = new ApolloClient({
    link: ApolloLink.from([authLink, sentryLink, httpLink]),
    cache,
  });
  return client;
};
