import React from 'react';
import styled from 'styled-components';
import { TextField, Button, Typography, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { COLOR_BAD } from '../constants';

// Shamelessly yoinked from SalesHandler, minus modification logic

interface EditableEntryStyleProps {
  empty: boolean;
  editable: boolean;
  bad: boolean;
}

const EditableEntryStyle = styled.span<EditableEntryStyleProps>`
  ${(props) => props.bad && `color: ${COLOR_BAD};`}

  ${(props) =>
    props.empty &&
    `
    & .empty {
      color: ${COLOR_BAD};
      font-weight: 600;
    }
  `};

  ${(props) => props.editable && `cursor: pointer;`}
`;

const EditStyle = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
  }

  .cancel {
    font-size: 0.7rem;
    position: absolute;
    top: 3px;
    right: 45px;
    height: 25px;
    width: 35px;
  }

  .ok {
    font-size: 0.7rem;
    position: absolute;
    top: 3px;
    right: -24px;
    height: 25px;
    width: 35px;
  }
`;

export interface EditableDisplayProps {
  editable: boolean;
  badMessage?: string;
  uppercase?: boolean;
  multiline?: boolean;
  save: (value: string) => void;
  value: string;
  inputType?: string;
  style?: React.CSSProperties;
  editStyle?: React.CSSProperties;
  options?: string[];
  suffix?: string;
}

interface EditableDisplayState {
  editing: boolean;
  editValue: string;
}

const identity = <T,>(v: T) => v;

export class EditableDisplay extends React.Component<
  EditableDisplayProps,
  EditableDisplayState
> {
  constructor(props: EditableDisplayProps) {
    super(props);
    this.state = {
      editing: false,
      editValue: '',
    };
  }

  wantsToEdit = () => {
    if (!this.props.editable) return;
    this.setState({
      editing: true,
      editValue: this.props.value,
    });
  };

  saveValue = () => {
    this.setState({ editing: false });
    this.props.save(this.state.editValue);
  };

  cancelSet = () => {
    this.setState({ editing: false, editValue: this.props.value });
  };

  editInputValue = (event: any) => {
    let newVal = event.target.value as string;
    if (this.props.uppercase) newVal = newVal.toUpperCase();
    this.setState({
      editValue: newVal,
    });
  };

  editOption = (_event: any, value: string | null) => {
    this.setState({ editValue: value ?? '' });
  };

  handleInputKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.saveValue();
    }
  };

  render() {
    if (this.state.editing) {
      return (
        <EditStyle>
          {this.props.options ? (
            <Autocomplete
              options={this.props.options}
              getOptionLabel={identity}
              style={{
                width: '100%',
                ...this.props.style,
                ...this.props.editStyle,
              }}
              ListboxProps={{ style: this.props.style }}
              onKeyPress={this.handleInputKeyPress}
              onChange={this.editOption}
              value={this.state.editValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type={this.props.inputType}
                  variant="standard"
                />
              )}
            />
          ) : (
            <TextField
              variant="standard"
              multiline={this.props.multiline}
              style={{
                width: '100%',
                ...this.props.style,
                ...this.props.editStyle,
              }}
              type={this.props.inputType}
              onKeyPress={this.handleInputKeyPress}
              onChange={this.editInputValue}
              value={this.state.editValue}
            />
          )}
          <div className="buttons">
            <Button
              className="cancel"
              variant="contained"
              color="primary"
              onClick={this.cancelSet}
            >
              Cancel
            </Button>
            <Button
              className="ok"
              variant="contained"
              color="secondary"
              onClick={this.saveValue}
            >
              OK
            </Button>
          </div>
        </EditStyle>
      );
    }

    const data = this.props.value.toString().trim();
    const display = (
      <EditableEntryStyle
        bad={this.props.badMessage != null}
        editable={this.props.editable}
        onClick={this.wantsToEdit}
        empty={data === ''}
      >
        {data === '' ? (
          <Typography className="empty" style={this.props.style}>
            EMPTY
          </Typography>
        ) : (
          <Typography
            style={{
              whiteSpace: this.props.multiline ? 'pre-line' : undefined,
              ...(this.props.style ?? {}),
            }}
          >
            {this.props.value}
            {this.props.suffix}
          </Typography>
        )}
      </EditableEntryStyle>
    );

    if (this.props.badMessage) {
      return <Tooltip title={this.props.badMessage}>{display}</Tooltip>;
    }

    return display;
  }
}
