
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ReceiptCreateManualError": [
      "ReceiptCreateManualConflictingPacksError",
      "ReceiptCreateManualInvalidInputError",
      "ReceiptCreateManualNotFoundError"
    ],
    "ReceiptDeletePacksError": [
      "ReceiptDeletePacksNotFoundError"
    ],
    "ReceiptPackReplaceTallyError": [
      "ReceiptPackReplaceTallyInvalidInputError",
      "ReceiptPackReplaceTallyNotFoundError",
      "ReceiptPackReplaceTallyProductKindError"
    ],
    "ReceiptPackSetPieceCountError": [
      "ReceiptPackSetPieceCountInvalidInputError",
      "ReceiptPackSetPieceCountNotFoundError",
      "ReceiptPackSetPieceCountProductKindError"
    ],
    "ReceiptPackUpdateKilnDryDateError": [
      "ReceiptPackUpdateKilnDryDateNotFoundError"
    ],
    "ReceiptPackUpdateProductCodeError": [
      "ReceiptPackUpdateProductCodeInvalidInputError",
      "ReceiptPackUpdateProductCodeNotFoundError"
    ],
    "ReceiptPacksUpdatePurchaseOrderNumberError": [
      "ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError",
      "ReceiptPacksUpdatePurchaseOrderNumberNotFoundError"
    ],
    "ReceiptScanPacksError": [
      "ReceiptScanPacksConflictingPacksError",
      "ReceiptScanPacksInvalidInputError"
    ],
    "ReceiptSendPacksError": [
      "ReceiptSendPacksNotFoundError",
      "ReceiptSendPacksNotSendableError",
      "ReceiptSendPacksSendingFailedError"
    ],
    "ReceiptSubmitUploadError": [
      "ReceiptSubmitUploadConflictingPacksError",
      "ReceiptSubmitUploadInvalidInputError"
    ],
    "ReceiptUploadUpdateDateDispatchedError": [
      "ReceiptUploadUpdateDateDispatchedNotFoundError"
    ],
    "ReceiptUploadUpdateReferenceError": [
      "ReceiptUploadUpdateReferenceInvalidInputError",
      "ReceiptUploadUpdateReferenceNotFoundError"
    ],
    "UserError": [
      "ReceiptCreateManualConflictingPacksError",
      "ReceiptCreateManualInvalidInputError",
      "ReceiptCreateManualNotFoundError",
      "ReceiptDeletePacksNotFoundError",
      "ReceiptPackReplaceTallyInvalidInputError",
      "ReceiptPackReplaceTallyNotFoundError",
      "ReceiptPackReplaceTallyProductKindError",
      "ReceiptPackSetPieceCountInvalidInputError",
      "ReceiptPackSetPieceCountNotFoundError",
      "ReceiptPackSetPieceCountProductKindError",
      "ReceiptPackUpdateKilnDryDateNotFoundError",
      "ReceiptPackUpdateProductCodeInvalidInputError",
      "ReceiptPackUpdateProductCodeNotFoundError",
      "ReceiptPacksUpdatePurchaseOrderNumberInvalidInputError",
      "ReceiptPacksUpdatePurchaseOrderNumberNotFoundError",
      "ReceiptScanPacksConflictingPacksError",
      "ReceiptScanPacksInvalidInputError",
      "ReceiptSendPacksNotFoundError",
      "ReceiptSendPacksNotSendableError",
      "ReceiptSendPacksSendingFailedError",
      "ReceiptSubmitUploadConflictingPacksError",
      "ReceiptSubmitUploadInvalidInputError",
      "ReceiptUploadUpdateDateDispatchedNotFoundError",
      "ReceiptUploadUpdateReferenceInvalidInputError",
      "ReceiptUploadUpdateReferenceNotFoundError"
    ]
  }
};
      export default result;
    