import React from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  ReceiptPackStatus,
  PendingPacksQuery,
  useUpdateProductCodeMutation,
  useUpdatePurchaseOrderNumberMutation,
  useSendPacksMutation,
} from '../generated/graphql';
import { HourglassFull, CheckCircle, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { EditableDisplay } from './EditableDisplay';
import { displayUserErrors } from '../utils/errors';
import { KilnDryDate } from './KilnDryDate';
import { ManageTally } from './ManageTally';
import { ManagePieceCount } from './ManagePieceCount';
import Swal from 'sweetalert2';
import { useNow } from '../utils';

type Pack = PendingPacksQuery['uploaded'][0]['packs'][0];

export const PackRow: React.FC<{
  pack: Pack;
  productCodeMappings: string[];
  scanGroup?: boolean;
  readOnly?: boolean;
  onDeletePack: (packId: string) => void;
}> = ({ pack, productCodeMappings, scanGroup, readOnly, onDeletePack }) => {
  const frozen = readOnly || pack.status !== ReceiptPackStatus.New;
  const noDelete =
    readOnly ||
    (pack.status !== ReceiptPackStatus.New &&
      pack.status !== ReceiptPackStatus.Sending);

  const setProductCode = useSetProductCode(pack);
  const setPoNums = useSetPurchaseOrderNumbers(pack);

  return (
    <TableRow hover className="table-row table-row-upload">
      {!scanGroup && (
        <TableCell className="scanned-by">
          {pack.scanEvent?.scannedBy.givenName ?? (
            <HourglassFull style={{ width: 20 }} />
          )}
        </TableCell>
      )}
      <TableCell>{pack.packNumber}</TableCell>
      {!scanGroup && (
        <>
          <TableCell>
            <EditableDisplay
              editable={!frozen}
              style={{ fontSize: '0.95rem' }}
              value={pack.productCode ?? ''}
              save={setProductCode}
              options={productCodeMappings}
            />
            <div style={{ fontSize: '0.7rem' }}>{pack.supplierProductCode}</div>
          </TableCell>
          <TableCell>
            {frozen ? (
              pack.kilnDryDate != null &&
              format(new Date(pack.kilnDryDate), 'dd.MM.yyyy')
            ) : (
              <KilnDryDate
                packId={pack.id}
                sendable={pack.sendable}
                date={pack.kilnDryDate ?? null}
              />
            )}
          </TableCell>
          <TableCell>
            <EditableDisplay
              editable={!frozen}
              style={{ fontSize: '0.9rem', width: 40 }}
              value={pack.purchaseOrderNumber ?? ''}
              save={setPoNums}
            />
          </TableCell>
          <TableCell>
            {pack.pieceCount != null ? (
              <ManagePieceCount
                packId={pack.id}
                sendable={pack.sendable}
                readOnly={frozen}
                pieceCount={pack.pieceCount}
              />
            ) : (
              <ManageTally
                packId={pack.id}
                sendable={pack.sendable}
                readOnly={frozen}
                tally={pack.tally}
              />
            )}
          </TableCell>
          <TableCell>
            <div className="status">
              <PackStatus
                packId={pack.id}
                sendable={pack.sendable}
                dateTransitioned={pack.dateTransitioned}
                status={pack.status}
              />
            </div>
            {!noDelete && (
              <IconButton
                style={{ position: 'absolute', top: -10, right: -10 }}
                onClick={() => onDeletePack(pack.id)}
                size="large"
              >
                <Close style={{ width: 20, height: 20 }} />
              </IconButton>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const PackStatus: React.FC<{
  packId: string;
  sendable: boolean;
  dateTransitioned: string;
  status: ReceiptPackStatus;
}> = ({ packId, sendable, dateTransitioned, status }) => {
  const [sendPacks] = useSendPacksMutation();

  const handleSendPacks = (allowRetry: boolean) => {
    displayUserErrors(
      'result',
      sendPacks({
        variables: {
          input: {
            packIds: [packId],
            allowRetry,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          result: {
            __typename: 'ReceiptSendPacksPayload',
            packs: [
              {
                __typename: 'ReceiptPack',
                id: packId,
                sendable: false,
                dateTransitioned: new Date().toISOString(),
                // If all goes well, will become Sent when the actual response gets back
                status: ReceiptPackStatus.Sending,
              },
            ],
            userErrors: [],
          },
        },
      }),
      true,
    );
  };

  if (status === ReceiptPackStatus.New) {
    return (
      <Button
        variant="contained"
        disableElevation
        color="primary"
        disabled={!sendable}
        onClick={() => handleSendPacks(false)}
      >
        Send
      </Button>
    );
  }

  if (status === ReceiptPackStatus.Sent) {
    return <CheckCircle style={{ width: 20, height: 20 }} />;
  }

  if (status === ReceiptPackStatus.Sending) {
    return (
      <SendingStatus
        dateTransitioned={dateTransitioned}
        send={() => handleSendPacks(true)}
      />
    );
  }

  return null;
};

const SendingStatus: React.FC<{
  dateTransitioned: string;
  send: () => void;
}> = ({ dateTransitioned, send }) => {
  const now = useNow(15000);

  // If more than 40 seconds ago, almost certainly failed to send.
  const cutoff = new Date(now.getTime() - 40 * 1000);
  if (new Date(dateTransitioned) < cutoff) {
    return (
      <Button
        color="secondary"
        onClick={async () => {
          const result = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Please first check that the pack has not been created in the inventory system.',
            showCancelButton: true,
          });
          if (!result.isConfirmed) return;
          send();
        }}
      >
        Retry?
      </Button>
    );
  }

  return <CircularProgress style={{ width: 20, height: 20 }} />;
};

function useSetProductCode(pack: Pack) {
  const [mutate] = useUpdateProductCodeMutation();
  return (code: string) => {
    return displayUserErrors(
      'result',
      mutate({
        variables: {
          input: {
            packId: pack.id,
            productCode: code || null,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          result: {
            __typename: 'ReceiptPackUpdateProductCodePayload',
            pack: {
              __typename: 'ReceiptPack',
              id: pack.id,
              sendable: pack.sendable,
              productCode: code || null,
              // Assume this stays, will get updated soon enough by server
              // otherwise
              pieceCount: pack.pieceCount,
            },
            userErrors: [],
          },
        },
      }),
      true,
    );
  };
}

function useSetPurchaseOrderNumbers(pack: Pack) {
  const [mutate] = useUpdatePurchaseOrderNumberMutation();
  return (po: string) => {
    return displayUserErrors(
      'result',
      mutate({
        variables: {
          input: {
            packIds: [pack.id],
            purchaseOrderNumber: po,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          result: {
            __typename: 'ReceiptPacksUpdatePurchaseOrderNumberPayload',
            packs: [
              {
                __typename: 'ReceiptPack',
                id: pack.id,
                sendable: pack.sendable,
                purchaseOrderNumber: po,
              },
            ],
            userErrors: [],
          },
        },
      }),
      true,
    );
  };
}
