import styled from 'styled-components';

export const CardStyle = styled.div`
  position: relative;
  margin-bottom: 2rem;

  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;

  .header {
    height: 7em;
    padding: 0 4em 0 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eee;
    border-radius: 10px 10px 0 0;
  }

  .header-left {
    display: flex;
    *:first-child {
      margin-right: 1em;
    }
  }

  .table-header {
    background-color: #68ab4a0f;
    margin: 0.25em 0;
    border-bottom: 4px solid #fefefe;
    border-top: 4px solid #fefefe;

    th:first-child,
    th:last-child,
    th:nth-last-child(2) {
      text-align: center;
    }
  }

  &.upload .table-header th:nth-child(2) {
    text-align: center;
  }

  .table-row {
    background-color: #f0ecec;

    td:first-child,
    td:nth-last-child(2) {
      text-align: center;
    }

    &:last-child {
      td {
        border-bottom: none;
      }

      td:first-child {
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  .table-row-upload {
    width: 100%;
    position: relative;
    height: 90px;
  }

  .scanned-by {
    background: rgba(217, 217, 217, 0.65);
  }

  &.upload .table-row td:nth-child(2) {
    text-align: center;
  }

  .status {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
